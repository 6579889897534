import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { FaCoins } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { GiTakeMyMoney, GiGrowth, GiLevelThreeAdvanced } from "react-icons/gi";
import {
  MdAttachMoney,
  MdOutlineDirectionsWalk,
} from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { copy_refral, copy_wallet } from "../../../helpers/helper";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import moment from "moment/moment";
import RangeSlider from "react-bootstrap-range-slider";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { activateDscToken, activateUsdtToken, activatestDSCToken, getBalanceUser, getTokenPrice } from "../../../helpers/getWeb3";
import { DSC_Token_Address, USDT_Token_ADDRESS, stDSC_Token_Address } from "../../../helpers/config";
import { getDashBoardDetails, getUserDetailsByWallet, withDrawOtherIncome, withDrawSponserIncome } from "../../../helpers/api";
import { Loader } from "./Loader";
const Home = () => {


  const [btnLoader, setBtnLoader] = useState(false)
  const [loader, setLoader] = useState(true)
  const [userData, setUserData] = useState()
  const [userDashData, setUserDashData] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [with1, setRefreshWith1] = useState(false)
  const [with2, setRefreshWith2] = useState(false)

  const [currentPriceDsc, setCurrentPriceDsc] = useState("")
  const [loading, setLoading] = useState(false);
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });
  const [currentPrice, setCurrentPrice] = useState(0)
  const [tokenBalance, setTokenBalnce] = useState(0);
  const [basicModal, setBasicModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [selectedToken, setSelectedToken] = useState("");
  const [selectedTokenName, setSelectedTokenName] = useState("");
  const [type, setType] = useState("");
  const [typeWith, setWithType] = useState(1);

  const [tokenAmount, setTokenAmount] = useState("")
  const [packageStaking, setPackageStaking] = useState(10000);
  const [value, setValue] = useState(60);
  const [packageBtwenAmt, setPackagebtwAmt] = useState(60);
  const alltoken = [
    { address: USDT_Token_ADDRESS, symbol: "USDT" },
    { address: stDSC_Token_Address, symbol: "st-DSC" },
    { address: DSC_Token_Address, symbol: "DSC" }

  ]

  const planType = [
    { type: 1, symbol: "Flexi" },
    { type: 2, symbol: "Fix" },


  ]
  const incomeType = [
    { type: 1, symbol: "Working Income" },
    { type: 2, symbol: "Non Working Income" },

  ]

  const handleTokenChange = (event) => {
    const { value, selectedOptions } = event.target;
    setSelectedTokenName({ value: value, name: selectedOptions[0].text });
    setSelectedToken(value);

  };

  const handleType = (event) => {
    const { value, selectedOptions } = event.target;
    //setType({ value: value, name: selectedOptions[0].text });
    setType(value);

  };
  const handleWithdrawType = (event) => {
    const { value, selectedOptions } = event.target;
    //setType({ value: value, name: selectedOptions[0].text });
    setWithType(value);

  };


  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (!selectedToken) return;

    getBalanceUser(walletAddress, selectedToken).then((res) => {
      const balance = Number(res?.value / 1e18) || 0;
      setTokenBalnce(balance);
    });

    if (selectedTokenName?.name === 'DSC') {
      getTokenPrice().then(setCurrentPrice);
    } else if (selectedTokenName?.name === 'USDT') {
      setCurrentPrice(1);
    } else {
      setCurrentPrice(89.28);
    }

  }, [selectedToken, refresh, with1, with2]);
  function Buy() {
    if (walletAddress) {
      if (selectedToken) {
        if (type) {
          getUserDetailsByWallet(walletAddress).then((res) => {
            console.log(res, "call:::")
            if(value>=60){

              if (value > res?.lastStake ) {

                if (res?.isallow) {
                  if (selectedTokenName?.name === 'DSC') {
  
                    activateDscToken(
                      walletAddress,
                      value,
                      selectedToken,
                      type,
                      tokenAmount,
                      setLoading,
                      setRefresh
                    );
  
                  } else if (selectedTokenName?.name === 'USDT') {
  
                    activateUsdtToken(
                      walletAddress,
                      value,
                      selectedToken,
                      type,
                      tokenAmount,
                      setLoading,
                      setRefresh
                    );
  
                  } else {
  
                    activatestDSCToken(
                      walletAddress,
                      value,
                      selectedToken,
                      type,
                      tokenAmount,
                      setLoading,
                      setRefresh
                    );
                  }
  
                } else {
  
                  toast.error('Activate limit Exceed !')
                }
              } else {
  
                toast.error('Activation amount must be greater than previous Stake amount !')
              }
            }  else {

              toast.error('Amount must be greater than 60 $!')
            }
           
          });
        } else {
          toast.error('Select type !')
        }
      } else {
        toast.error('Select Token !')
      }
    }

  }
  useEffect(() => {
    getDashBoardDetails(walletAddress).then((res) => {


      setUserDashData(res);


    });
    getUserDetailsByWallet(walletAddress).then((res) => {
      setUserData(res);


    });
    const timeoutId = setTimeout(() => {
      getUserDetailsByWallet(walletAddress).then((res) => {
        setUserData(res);
        setLoader(false);

      });
    }, 3000);
    return () => clearTimeout(timeoutId);

  }, [walletAddress, refresh, with1, with2]);

  useEffect(() => {
    if (currentPrice > 0) {
      setTokenAmount(Number(currentPrice * value))
    }
  }, [value, currentPrice])

  useEffect(() => {
    getTokenPrice().then((res) => {

      setCurrentPriceDsc(res)
    });
  }, [])
  function claimSponserIncome() {
    withDrawSponserIncome(userDashData?.walletReg_Income, walletAddress).then((res) => {

      console.log(res, "djdjdjdj")
      if (res.status) {
        setRefreshWith1(true)
        toast.success(res?.message)
      } else {
        toast.error(res?.message)
      }
    }).catch((err) => {

      toast.error(err)
    })
  }

  function claimOtherIncome() {
    withDrawOtherIncome(userDashData?.walletincome, walletAddress).then((res) => {
      if (res.status) {
        toast.success(res?.message)
        setRefreshWith2(true)

      } else {
        toast.error(res?.message)
      }
    }).catch((err) => {

      toast.error(err)
    })
  }
  const maxGreenWidth = 90;
  const cappingLimit = userDashData?.totalCapping;
  const cappingUsed = userDashData?.cappingUsed;
  const progress = (cappingUsed / cappingLimit) * 100;

  const backgroundColor = progress > maxGreenWidth ? "bg-danger" : "bg-warning";

  const progressBarStyle = {
    width: `${progress}%`,
  };
  const handleChange = (event) => {
    const newValue = event.target.value;
    // Ensure the value is within the defined range
    if (newValue <= 10000) {
      setValue(newValue);
    }
  };

  return (
    <>
      <div className="row mt-4">
        {loader ? (
          <Loader />
        ) : (
          <>
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="card">
                <div class=" d-block card-header">
                  <div class="card-title text-white text-center h6">Cap Limit Tracker</div>
                  <hr />

                  <h6 className="text-white text-center "> Remaining Cap Limit -  <span className="text-danger fw-bolder"> {" "}
                    ${" "}
                    {(userDashData?.totalCapping - userDashData?.cappingUsed)?.toFixed(3)}</span></h6>

                  <h6 className="text-white text-center "> Capping -  <span className="text-danger fw-bolder">{userDashData?.capping_x} X</span></h6>
                  <h6 className="text-white text-center "> Type -  <span className="text-danger fw-bolder">{userDashData?.current_plan} </span></h6>

                  <div className="d-flex justify-content-between">
                    <div className="">
                      <span className="headingTxt text-white">Total Cap Limit </span>
                      <div className="amntTxt text-white">
                        {" "}
                        $ {userDashData?.totalCapping}
                      </div>
                    </div>

                    <div>
                      <span className="headingTxt text-white"> Cap Limit Utilized </span>
                      <div className="amntTxt text-white">
                        {" "}
                        ${" "}
                        {(userDashData?.cappingUsed)?.toFixed(3)}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="progress">
                    <div
                      role="progressbar"
                      className={`progress-bar ${backgroundColor}`}
                      style={progressBarStyle}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row">

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card newcard1 bg-success">
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3">
                          <i className="flaticon-381-calendar-1 "></i>
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1"> User ID</p>
                          {!isVisible ? (
                            <h4 className="text-white">
                              {userData?.userDetails?.userId}
                            </h4>
                          ) : "*********"}

                          <div onClick={toggleVisibility} className=" mt-2 " style={{ cursor: 'pointer' }}>
                            {isVisible ? <MdVisibilityOff size={20} />
                              : <MdVisibility size={20} />

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3">
                          <BsFillCalendarDateFill />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Joining Date & Time</p>
                          <h4 className="text-white">
                            {moment(userData?.userDetails?.createdAt).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3">
                          <MdOutlineDirectionsWalk />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">My Directs</p>
                          <h4 className="text-white">{userDashData?.myDirects ? userDashData?.myDirects : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3">
                          <AiOutlineTeam />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Total Team</p>
                          <h4 className="text-white">{userDashData?.myTotalTeam ? userDashData?.myTotalTeam : 0}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3">
                          <i className="la la-dollar"></i>
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">Directs Business (USDT)</p>
                          <h4 className="text-white">
                            {userDashData?.myDirectsBusiness ? userDashData?.myDirectsBusiness : 0}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3">
                          <GiTakeMyMoney />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Total Team Business (USDT)</p>
                          <h4 className="text-white">
                            {userDashData?.myTotalTeamBusiness ? userDashData?.myTotalTeamBusiness : 0}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3">
                          <FaCoins />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">My Staking (USDT)</p>
                          <h4 className="text-white">
                            {userDashData?.myStaking ? userDashData?.myStaking : 0}

                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#9341c1" }}
                  >
                    {/* {console.log(userIncome,"income::")} */}
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3  bgl-danger text-ifo">
                          <MdAttachMoney />
                        </span>
                        <div className="media-body text-white">
                          <p className="">My Staking Bonus</p>
                          <h4 className="text-white">
                            {userDashData?.roiBonus ? userDashData?.roiBonus?.toFixed(4) : 0} {" "}

                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>





                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className="">Sponser Registration Income </p>
                          <h4 className="text-white">
                            {userDashData?.registraionLevel ? userDashData?.registraionLevel?.toFixed(2) : 0} {" "}
                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className="">Sponser Activation Income </p>
                          <h4 className="text-white">
                            {userDashData?.sponsorIncome ? userDashData?.sponsorIncome?.toFixed(2) : 0} {" "}

                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"

                  >
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className=""> Working Income</p>
                          <h4 className="text-white">
                            {(userDashData?.totalReg_Withdraw + userDashData?.walletReg_Income)?.toFixed(3)} {" "}

                            USDT
                          </h4>
                          <h6 className="text-white">
                            Withdrawn | Available Bonus
                          </h6>

                          <p>
                            {userDashData?.totalReg_Withdraw ? userDashData?.totalReg_Withdraw?.toFixed(2) : 0} {" "}
                            |   {userDashData?.walletReg_Income ? userDashData?.walletReg_Income?.toFixed(2) : 0} {" "} USDT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#9341c1" }}
                  >
                    {/* {console.log(userIncome,"income::")} */}
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3  bgl-danger text-ifo">
                          <MdAttachMoney />
                        </span>
                        <div className="media-body text-white">
                          <p className="">Non-Working Income</p>

                          <h4 className="text-white">
                            {(userDashData?.walletincome + userDashData?.totalWithdraw)?.toFixed(3)} {" "}


                            USDT
                          </h4>
                          <h6 className="text-white">
                            Withdrawn | Available Bonus
                          </h6>

                          <p>
                            {userDashData?.totalWithdraw ? userDashData?.totalWithdraw?.toFixed(4) : 0} {" "} {" "}
                            |    {userDashData?.walletincome ? userDashData?.walletincome?.toFixed(4) : 0} {" "}  {" "} USDT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  >
                    <div className="card-body p-2">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <GiLevelThreeAdvanced />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1 ">
                            My Level Bonus (USDT)
                          </p>
                          <h4 className="text-white">
                            {userDashData?.myLevelBonus ? userDashData?.myLevelBonus?.toFixed(4) : 0}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className="">My Reward Bonus </p>
                          <h4 className="text-white">
                            0
                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-2">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className="">Total Income </p>
                          <h4 className="text-white">
                            {(userDashData?.totalIncome + userDashData?.walletReg_Income + userDashData?.totalReg_Withdraw)?.toFixed(4)} {" "}
                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="row">
                <div className="col-xl-12 col-sm-6">
                  <div className="card h-auto">
                    <div className="card-body px-0 pt-1">
                      <Tab.Container defaultActiveKey="Navbuy">
                        <div className="">
                          <div className="buy-sell">
                            <Nav
                              className="nav nav-tabs"
                              eventKey="nav-tab2"
                              role="tablist"
                            >
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navbuy"
                                type="button"
                              >
                                Activate
                              </Nav.Link>
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navsell"
                                type="button"
                              >
                                Withdraw
                              </Nav.Link>
                            </Nav>
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="Navbuy">
                              <Tab.Container defaultActiveKey="Navbuymarket">
                                <Tab.Content id="nav-tabContent1">
                                  <Tab.Pane eventKey="Navbuymarket"></Tab.Pane>
                                  <Tab.Pane eventKey="Navbuylimit"></Tab.Pane>
                                </Tab.Content>
                                <div className="sell-element">
                                  <div className="text-center">

                                    <span className="text-warning">Current Price :</span> {"  "}
                                    1 USDT ~ {currentPrice?.toFixed(4)} {"  "}{"  "}
                                    {selectedTokenName ? selectedTokenName?.name
                                      : null}


                                  </div>
                                  <div className="row">
                                    <div className=" text-end pt-2 text-success mb-2">
                                      {tokenBalance}{" "}
                                      {selectedTokenName ? selectedTokenName?.name
                                        : null}
                                      <span className="text-primary">
                                        {" "}
                                        Token Balnce{" "}
                                      </span>{" "}
                                    </div>

                                    <div className="col-xl-6 col-lg-6">
                                      <div className="d-flex justify-content-space-between">
                                        <div className="card-title pt-3 text-white">Token</div>
                                      </div>
                                    </div>
                                    <div className="ms-1 me-1">
                                      <select
                                        class="form-select form-select-sm"
                                        aria-label="Small select example"
                                        onChange={handleTokenChange}
                                        value={selectedToken}

                                      >
                                        <option value="" disabled>--Select Token --</option>

                                        {alltoken?.map((tkn, i) => {
                                          return (
                                            <option value={tkn.address}>
                                              {tkn?.symbol}
                                            </option>
                                          );
                                        })}


                                      </select>
                                    </div>



                                    <div className="justify-content-center btn-register">
                                      <h5 className="card-title pt-1 text-white">
                                        Select Your Package
                                      </h5>
                                      <input
                                        id="numberInput"
                                        type="number"
                                        className="form-control mb-3" // Bootstrap class for styling
                                        value={value}
                                        onChange={handleChange}
                                         min="60"
                                        max="10000"
                                      />  
                                      <RangeSlider
                                        step={1}
                                        value={value}
                                        min={packageBtwenAmt}
                                        max={packageStaking}
                                        onChange={(changeEvent) =>
                                          setValue(
                                            changeEvent.target.value < 60
                                              ? 60
                                              : changeEvent.target.value
                                          )
                                        }
                                        variant="success"
                                      />
                                                                      <div className="row justify-content-center btn-register">
                                        <div className="col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-primary ps-1 pe-1"
                                            onClick={() => {
                                              setPackageStaking(1000);
                                              setPackagebtwAmt(60);
                                              setValue(60);
                                            }}
                                          >
                                            $60-$1000 <br />
                                            <span className="text-warning">  (Beginner)</span>
                                          </button>
                                        </div>
                                        <div className="col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-primary ps-1 pe-1"
                                            onClick={() => {
                                              setPackageStaking(3000);
                                              setPackagebtwAmt(1001);
                                              setValue(1001);
                                            }}
                                          >
                                            $1001-$3000 <br />
                                            <span className="text-info">  (Learner)</span>
                                          </button>
                                        </div>
                                        <div className="col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-primary ps-1 pe-1"
                                            onClick={() => {
                                              setPackageStaking(10000);
                                              setPackagebtwAmt(3001);
                                              setValue(3001);
                                            }}
                                          >
                                            $3001-$10000 <br />
                                            <span className="text-danger">(Expert)</span>
                                          </button>
                                        </div>

                                      </div>

                                    </div>

                                    <div className="ms-1 me-1">
                                      <h5 className="card-title pt-1 text-white">
                                        Select Type
                                      </h5>
                                      <select
                                        class="form-select form-select-sm"
                                        aria-label="Small select example"
                                        onChange={handleType}
                                        value={type}

                                      >
                                        <option value="" disabled>--Select Type --</option>

                                        {planType?.map((tkn, i) => {
                                          return (
                                            <option value={tkn?.type}>
                                              {tkn?.symbol}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="">

                                      <hr />
                                      <div className="text-center">

                                        <span className="text-warning">Calculate :</span> {"  "}
                                        {value}  USDT ~ {((currentPrice) * value)?.toFixed(2)} {"  "}{"  "}
                                        {selectedTokenName ? selectedTokenName?.name
                                          : null}


                                      </div>
                                      <div className="pt-1  mb-1">
                                        {" "}
                                        Note :-  {"  "}
                                        <span className=" ps-1 text-success">

                                          {type == 1 ? 'Flexi : Can Withdraw Anytime Minimum $10' : type == 2 ? 'Fix : Can Withdraw Only When Package Becomes 200%' : null}


                                        </span>
                                      </div>



                                    </div>
                                  </div>

                                  <div className="text-center mt-3">
                                    {loading ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        onClick={() => Buy()}
                                        className="btn btn-primary w-75"
                                      >
                                        BUY
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Navsell">
                              <Tab.Container defaultActiveKey="Navsellmarket">
                                <div className="sell-element">
                                  <div className="ms-1 me-1">
                                    <h5 className="card-title pt-1 text-white">
                                      Select Withdraw Type
                                    </h5>
                                    <select
                                      class="form-select form-select-sm"
                                      aria-label="Small select example"
                                      onChange={handleWithdrawType}
                                      value={typeWith}

                                    >


                                      {incomeType?.map((tkn, i) => {
                                        return (
                                          <option value={tkn?.type}>
                                            {tkn?.symbol}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {typeWith == 1 ?
                                    <>
                                      <div className="sell-blance mt-3">
                                        <label className="form-label text-primary">
                                          Working Income (USDT)
                                        </label>

                                        <div className="input-group mt-2 mb-2">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0.00"
                                            value={userDashData?.walletReg_Income?.toFixed(4)}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="text-center">
                                        {btnLoader ? (
                                          <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                            <span
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            Loading...
                                          </button>
                                        ) : (
                                          <div
                                            className="btn btn-primary w-75"
                                            onClick={() => {
                                              // claimRewardAmt(walletAddress)
                                              if (userDashData?.walletReg_Income > 0) {
                                                setBasicModal(true)
                                              } else {
                                                toast.error("amount must be greater than 0!");
                                              }
                                            }
                                            }
                                          >
                                            Withdraw

                                          </div>
                                        )}
                                      </div>
                                    </>

                                    :
                                    <>
                                      <div className="sell-blance mt-3">
                                        <label className="form-label text-primary pt-3">
                                          Non-Working Income (USDT)

                                        </label>

                                        <div className="input-group mt-2 mb-2">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0.00"
                                            value={userDashData?.walletincome?.toFixed(4)}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="text-center">
                                        {btnLoader ? (
                                          <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                            <span
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            Loading...
                                          </button>
                                        ) : (
                                          <div
                                            className="btn btn-primary w-75"
                                            onClick={() => {


                                              if (userDashData?.walletincome > 0) {
                                                setModal2(true)
                                              } else {
                                                toast.error("amount must be greater than 0!");
                                              }
                                            }

                                            }
                                          >
                                            Withdraw
                                          </div>
                                        )}
                                      </div>
                                    </>

                                  }




                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-sm-6">
                  <div className="card">
                    <div className="card-header py-2">
                      <h2 className="heading">Referral Id </h2>
                    </div>
                    <div className="input-group mb-3 p-2 mt-2">
                      <input
                        id="copy"
                        type="text"
                        className="form-control"
                        value={`https://dsclab.ai/register?user_id=${userData?.userDetails?.userId}`}
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => copy_refral()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-sm-6">
                  <div className="card">
                    <div className="card-header py-2">
                      <h2 className="heading">Wallet Address </h2>
                    </div>
                    <div className="input-group mb-3 p-2 mt-2">
                      <input
                        id="copywallet"
                        type="text"
                        className="form-control"
                        value={walletAddress}
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => copy_wallet()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <Modal className="fade" show={basicModal}>
              <Modal.Header>
                <Modal.Title>Working Income</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setBasicModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {/* <div className="fs-6 fw-bold">
                    <span className="text-success">
                      <span className="text-dark ">current Price:</span>{" "}
                      1 USD = {currentPriceDsc?.toFixed(3)} {" "} DSC
                    </span>
                  </div> */}
                  <div className="fs-6 fw-bold">
                    <span className="text-warning">
                      <span className="text-dark ">Total :</span>{" "}
                      {userDashData?.walletReg_Income?.toFixed(3)} Usdt
                    </span>
                  </div>
                  <div className="fs-6">
                    <span className="text-info">
                      <span className="text-dark ">Total Token Received :</span>  ≈ {" "}
                      The equivalent token amount will be calculated based on the current rate at the time of processing.
                    </span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setBasicModal(false)}
                  variant="danger light"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    claimSponserIncome()
                    setBasicModal(false);
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal className="fade" show={modal2}>
              <Modal.Header>
                <Modal.Title>Non-Working Income</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setModal2(false)}
                ></Button>

              </Modal.Header>
              <Modal.Body>

                <div className="text-center">

                  {/* <div className="fs-6 fw-bold">
                    <span className="text-success">
                      <span className="text-dark ">current Price:</span>{" "}
                      1 USD = {currentPriceDsc?.toFixed(3)} {" "} DSC
                    </span>
                  </div> */}
                  <div className="fs-6 fw-bold">
                    <span className="text-warning">
                      <span className="text-dark ">Total :</span>{" "}
                      {userDashData?.walletincome?.toFixed(3)} USD
                    </span>
                  </div>
                  <div className="fs-6 ">
                    <span className="text-info">
                      <span className="text-dark ">Total Token Received :</span>  ≈ {" "}
                      The equivalent token amount will be calculated based on the current rate at the time of processing.
                    </span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModal2(false)}
                  variant="danger light"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {

                    setModal2(false);
                    claimOtherIncome()
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};
export default Home;
